export function setMastheadHeight() {}

function lazySelector(el) {
  if (typeof el === 'string') {
    return document.querySelector(el);
  } else if (el instanceof Element || el?.nodeType === 1) {
    return el;
  }
  throw new Error(`Expected Element or String, got ${typeof el}.`);
}

export default class Masthead {
  constructor(el, options) {
    this._el = lazySelector(el);
    this._options = {
      dependencies: ['.header', '.promo-banner'],
      height: {
        min: 400,
      },
      ...options,
    };

    this._setHeight = this._setHeight.bind(this);

    this._setHeight();
    if (window.ontouchstart === undefined) {
      window.addEventListener('resize', this._setHeight);
    }
  }

  _setHeight() {
    const dependenciesHeight = this._options.dependencies.reduce(
      (height, dependency) => height + (lazySelector(dependency)?.getBoundingClientRect().height ?? 0),
      0
    );
    const height = window.innerHeight - dependenciesHeight;

    this._el.style.height = `${height < this._options.height.min ? this._options.height.min : height}px`;
  }
}
